<template>
  <header>
    <b-container>
      <b-row>
        <b-col
          cols="8"
          class="logo-container py-3"
        >
          <div
            class="logo c-pointer container"
            @click="redirectHome()"
          />
        </b-col>
        <b-col class="header-buttons">
          <b-row no-gutters>
            <b-col>
              <login-form
                v-if="!isAdmin"
                v-model="isAdmin"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-right">
              <language-list
                @loading="incLoading"
                @loaded="$emit('loaded')"
              />
              <b-button
                variant="outline-dark"
                class="border-0 ml-2"
                title="Home page"
                @click="redirectHome()"
              >
                <font-awesome-icon :icon="['fas', 'home']" />
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </header>
</template>

<script>
import LanguageList from '@/components/LanguageList'
import LoginForm from '@/components/admin/LoginForm'
import { useConfigurationStore } from '@/stores'
import { mapStores } from 'pinia'

export default {
  name: 'AppHeader',

  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },

  components: {
    LanguageList,
    LoginForm,
  },

  created () {
    this.$emit('loading', `${this.$t('loadingMessage.buildingHeader')}...`)
  },

  mounted () {
    this.configurationStore.load({ force: true })
      .then(() => {
        const logo = this.configurationStore.getByKey('LOGO_URL')
        if (logo && logo.value) {
          document.querySelector('#app .logo-container .logo').style.backgroundImage = `url('${logo.value}')`
        }
        const logoHeight = this.configurationStore.getByKey('LOGO_HEIGHT')
        if (logoHeight && logoHeight.value) {
          document.querySelector('#app .logo-container .logo').style.height = logoHeight.value
        }
      }).catch(() => {
        this.toastDanger(this.$t('errors.configurationsNotFound'), this.$t('errors.serverIssue'))
      }).finally(() => {
        this.$emit('loaded')
      })
  },

  computed: {
    ...mapStores(useConfigurationStore),

    isAdmin: {
      get () {
        return this.value
      },
      set (v) {
        this.$emit('input', v)
      },
    },
  },

  methods: {
    incLoading (msg) {
      this.$emit('loading', msg)
    },

    redirectHome () {
      this.$emit('home')
    },
  },
}
</script>

<style lang="scss" scoped>
.logo-container {
  .logo {
    background-image: url('../../themes/img/logo_140px.png');
    background-position: left center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 50px;
  }
}

.header-buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
