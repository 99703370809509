<template>
  <div>
    <b-modal
      id="modal-login"
      centered
      hide-footer
      @show="resetModal()"
      @hidden="resetModal()"
    >
      <template #modal-title>
        Connexion
        <font-awesome-icon
          :icon="['fas', 'undo']"
          class="text-primary c-pointer"
          title="Vider les données d'application"
          @click="emptyLocalStorage()"
        />
        <br>
        <small><sub>{{ versionNumber }}</sub></small>
      </template>

      <form
        ref="login-form"
        @submit.stop.prevent
      >
        <b-form-group label="Nom de compte">
          <b-form-input
            v-model="userLogIn"
            :state="loginState"
            required
          />
        </b-form-group>

        <b-form-group label="Mot de passe">
          <b-form-input
            v-model="passwordLogIn"
            :state="loginState"
            type="password"
            required
          />
        </b-form-group>

        <b-button
          type="submit"
          variant="primary"
          @click="logIn()"
        >
          Connexion
        </b-button>
      </form>
    </b-modal>

    <b-col v-if="!value">
      <font-awesome-icon
        :icon="['fas', 'user-lock']"
        class="float-right opacity-0 c-pointer"
        @click.ctrl.alt="$bvModal.show('modal-login')"
      />
    </b-col>
  </div>
</template>

<script>
import packageJson from '/package.json'

export default {
  name: 'LoginForm',

  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    versionNumber() {
      return packageJson.version
    },
  },

  data () {
    return {
      userLogIn: '',
      passwordLogIn: '',
      loginState: null,
    }
  },

  methods: {
    checkFormValidity () {
      const valid = this.$refs['login-form'].checkValidity()
      this.loginState = valid
      return valid
    },

    logIn () {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) return

      if (this.userLogIn === process.env.VUE_APP_ADMIN_USERNAME && this.passwordLogIn === process.env.VUE_APP_ADMIN_PASSWORD) {
        this.$emit('input', true)

        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide('modal-login')
        })
      }
    },

    resetModal () {
      this.userLogIn = ''
      this.passwordLogIn = ''
      this.loginState = null
    },

    emptyLocalStorage () {
      window.localStorage.setItem('survey-backup', '{}')
      window.location.replace(window.location.origin)
    },
  },
}
</script>
