<template>
  <b-dropdown
    id="language-list"
    variant="outline-light"
    size="sm"
    no-caret
  >
    <template #button-content>
      <span
        v-if="!getFlag(currentLang)"
        class="text-dark"
      >
        {{ currentLang.toUpperCase() }}
      </span>
      <div
        v-else
        class="text-dark flag"
        :class-name="`flag-${currentLang}`"
        :style="getFlag(currentLang)"
      >
      </div>
    </template>

    <b-dropdown-item
      v-for="(lang, index) of selectableLang"
      :key="index"
      href="#"
      @click="changeLang(lang.tag)"
    >
      <span
        v-if="!getFlag(lang.tag)"
        class="text-dark"
      >
        {{ lang.tag.toUpperCase() }}
      </span>
      <div
        v-else
        class="text-dark flag"
        :class-name="`flag-${lang.tag}`"
        :style="getFlag(lang.tag)"
      >
      </div>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { LocaleDataService } from '@/services'

export default {
  data () {
    return {
      availableLang: [],
      // Some flags are determined by the ISO code for phrases, while others use the ISO code of their respective countries.
      flags: {
        "at": { pos: { x: "-1", y: "-1" } }, // Austria
        "be": { pos: { x: "-19", y: "-1" } }, // Belgium
        "mu": { pos: { x: "-1", y: "-19" } }, // Mauritius
        "id": { pos: { x: "-19", y: "-19" } }, // Indonesia
        "ch": { pos: { x: "-37", y: "-1" } }, // Switzerland
        "om": { pos: { x: "-37", y: "-19" } }, // Oman
        // "et": { pos: { x: "-1", y: "-37" } }, // Ethiopia
        "tz": { pos: { x: "-19", y: "-37" } }, // Tanzania
        "en": { pos: { x: "-37", y: "-37" } }, // United Kingdom
        "np": { pos: { x: "-55", y: "-1" } }, // Nepal
        // "lb": { pos: { x: "-55", y: "-19" } }, // Lebanon
        "de": { pos: { x: "-55", y: "-37" } }, // Germany
        "it": { pos: { x: "-1", y: "-55" } }, // Italy
        "et": { pos: { x: "-19", y: "-55" } }, // Estonia
        "ru": { pos: { x: "-37", y: "-55" } }, // Russia
        "mc": { pos: { x: "-55", y: "-55" } }, // Monaco
        "pr": { pos: { x: "-73", y: "-1" } }, // Puerto Rico
        "tr": { pos: { x: "-73", y: "-19" } }, // Turkey
        "ja": { pos: { x: "-73", y: "-37" } }, // Japan
        "fr": { pos: { x: "-73", y: "-55" } }, // France
        "km": { pos: { x: "-1", y: "-73" } }, // Comoros
        "zh": { pos: { x: "-19", y: "-73" } }, // China
        "kp": { pos: { x: "-37", y: "-73" } }, // North Korea
        "hn": { pos: { x: "-55", y: "-73" } }, // Honduras
        "lb": { pos: { x: "-73", y: "-73" } }, // Luxembourg
        "lv": { pos: { x: "-91", y: "-1" } }, // Latvia
        "kn": { pos: { x: "-91", y: "-19" } }, // Saint Kitts and Nevis
        "jm": { pos: { x: "-91", y: "-37" } }, // Jamaica
        "sy": { pos: { x: "-91", y: "-55" } }, // Syria
        "mm": { pos: { x: "-91", y: "-73" } }, // Myanmar
        "qa": { pos: { x: "-1", y: "-91" } }, // Qatar
        "gh": { pos: { x: "-19", y: "-91" } }, // Ghana
        "aw": { pos: { x: "-37", y: "-91" } }, // Aruba
        "mr": { pos: { x: "-55", y: "-91" } }, // Mauritania
        "tg": { pos: { x: "-73", y: "-91" } }, // Togo
        "cf": { pos: { x: "-91", y: "-91" } }, // Central African Republic
        "gb-sct": { pos: { x: "-109", y: "-1" } }, // Scotland
        "bq-sa": { pos: { x: "-109", y: "-19" } }, // Saba Island
        "lt": { pos: { x: "-109", y: "-37" } }, // Lithuania
        "st": { pos: { x: "-109", y: "-55" } }, // Sao Tome and Principe
        "dj": { pos: { x: "-109", y: "-73" } }, // Djibouti
        "bq": { pos: { x: "-109", y: "-91" } }, // Bonaire
        "bj": { pos: { x: "-1", y: "-109" } }, // Benin
        "gw": { pos: { x: "-19", y: "-109" } }, // Guinea Bissau
        "td": { pos: { x: "-37", y: "-109" } }, // Chad
        "na": { pos: { x: "-55", y: "-109" } }, // Namibia
        "sr": { pos: { x: "-73", y: "-109" } }, // Suriname
        // "ga": { pos: { x: "-91", y: "-109" } }, // Gabon
        "bb": { pos: { x: "-109", y: "-109" } }, // Barbados
        "is": { pos: { x: "-127", y: "-1" } }, // Iceland
        "fm": { pos: { x: "-127", y: "-19" } }, // Micronesia
        "so": { pos: { x: "-127", y: "-37" } }, // Somalia
        "tn": { pos: { x: "-127", y: "-55" } }, // Tunisia
        "ko": { pos: { x: "-127", y: "-73" } }, // South Korea
        "xk": { pos: { x: "-127", y: "-91" } }, // Kosovo
        "my": { pos: { x: "-127", y: "-109" } }, // Malaysia
        "zw": { pos: { x: "-1", y: "-127" } }, // Zimbabwe
        "au": { pos: { x: "-19", y: "-127" } }, // Australia
        "py": { pos: { x: "-37", y: "-127" } }, // Paraguay
        "iq": { pos: { x: "-55", y: "-127" } }, // Iraq
        "cx": { pos: { x: "-73", y: "-127" } }, // Christmas Island
        "pt": { pos: { x: "-91", y: "-127" } }, // Portugal
        "ug": { pos: { x: "-109", y: "-127" } }, // Uganda
        "hi": { pos: { x: "-127", y: "-127" } }, // India
        "es": { pos: { x: "-145", y: "-1" } }, // Spain
        "cc": { pos: { x: "-145", y: "-19" } }, // Cocos Island
        "ai": { pos: { x: "-145", y: "-37" } }, // Anguilla
        "cv": { pos: { x: "-145", y: "-55" } }, // Cape Verde
        "sl": { pos: { x: "-145", y: "-73" } }, // Slovenia
        "ck": { pos: { x: "-145", y: "-109" } }, // Cook Islands
        "as": { pos: { x: "-145", y: "-127" } }, // American Samoa
        "do": { pos: { x: "-1", y: "-145" } }, // Dominican Republic
        "ar": { pos: { x: "-37", y: "-145" } }, // Saudi Arabia
        "gb-wls": { pos: { x: "-55", y: "-145" } }, // Wales
        "ni": { pos: { x: "-73", y: "-145" } }, // Nicaragua
        // "sv": { pos: { x: "-109", y: "-145" } }, // El Salvador
        "zm": { pos: { x: "-127", y: "-145" } }, // Zambia
        "ms": { pos: { x: "-145", y: "-145" } }, // Montserrat
        "bt": { pos: { x: "-163", y: "-1" } }, // Bhutan
        "es-ci": { pos: { x: "-163", y: "-19" } }, // Canary Islands
        "bi": { pos: { x: "-163", y: "-37" } }, // Burundi
        "ky": { pos: { x: "-163", y: "-55" } }, // Cayman Islands
        "io": { pos: { x: "-163", y: "-73" } }, // British Indian Ocean Territory
        "ad": { pos: { x: "-163", y: "-91" } }, // Andorra
        "ke": { pos: { x: "-163", y: "-109" } }, // Kenya
        "er": { pos: { x: "-163", y: "-127" } }, // Eritrea
        "kz": { pos: { x: "-163", y: "-145" } }, // Kazakhstan
        "tv": { pos: { x: "-1", y: "-163" } }, // Tuvalu
        "bl": { pos: { x: "-19", y: "-163" } }, // St. Barts
        "rs": { pos: { x: "-37", y: "-163" } }, // Serbia
        "bm": { pos: { x: "-55", y: "-163" } }, // Bermuda
        "sb": { pos: { x: "-73", y: "-163" } }, // Solomon Islands
        "ag": { pos: { x: "-91", y: "-163" } }, // Antigua and Barbuda
        "jo": { pos: { x: "-127", y: "-163" } }, // Jordan
        "bz": { pos: { x: "-163", y: "-163" } }, // Belize
        "ng": { pos: { x: "-181", y: "-1" } }, // Nigeria
        "ax": { pos: { x: "-181", y: "-19" } }, // Åland Islands
        "uy": { pos: { x: "-181", y: "-37" } }, // Uruguay
        "sx": { pos: { x: "-181", y: "-55" } }, // Sint Maarten
        "bf": { pos: { x: "-181", y: "-73" } }, // Burkina Faso
        "sk": { pos: { x: "-181", y: "-91" } }, // Slovakia
        // "sl": { pos: { x: "-181", y: "-109" } }, // Sierra Leone
        "es-pv": { pos: { x: "-181", y: "-127" } }, // Basque Country (Spain)
        "pn": { pos: { x: "-181", y: "-145" } }, // Pitcairn Islands
        "mz": { pos: { x: "-181", y: "-163" } }, // Mozambique
        "sm": { pos: { x: "-1", y: "-181" } }, // San Marino
        "gt": { pos: { x: "-19", y: "-181" } }, // Guatemala
        "al": { pos: { x: "-37", y: "-181" } }, // Albania
        "pk": { pos: { x: "-55", y: "-181" } }, // Pakistan
        // "Northern Cyprus" - Not included due to lack of universally recognized ISO code
        "es-ib": { pos: { x: "-91", y: "-181" } }, // Balearic Islands (Spain)
        "mh": { pos: { x: "-109", y: "-181" } }, // Marshall Islands
        "ec": { pos: { x: "-127", y: "-181" } }, // Ecuador
        "cm": { pos: { x: "-145", y: "-181" } }, // Cameroon
        "pa": { pos: { x: "-163", y: "-181" } }, // Panama
        "kw": { pos: { x: "-181", y: "-181" } }, // Kuwait
        "am": { pos: { x: "-199", y: "-1" } }, // Armenia
        "ro": { pos: { x: "-199", y: "-19" } }, // Romania
        "gn": { pos: { x: "-199", y: "-37" } }, // Guinea
        // "af": { pos: { x: "-199", y: "-55" } }, // Afghanistan
        "la": { pos: { x: "-199", y: "-73" } }, // Laos
        "gl": { pos: { x: "-199", y: "-91" } }, // Greenland
        "vg": { pos: { x: "-199", y: "-109" } }, // British Virgin Islands
        "hu": { pos: { x: "-199", y: "-127" } }, // Hungary
        "cw": { pos: { x: "-199", y: "-145" } }, // Curaçao
        "ao": { pos: { x: "-199", y: "-163" } }, // Angola
        "bn": { pos: { x: "-199", y: "-181" } }, // Brunei
        "bs": { pos: { x: "-1", y: "-199" } }, // Bahamas
        "nz": { pos: { x: "-19", y: "-199" } }, // New Zealand
        // "British Columbia" - Custom handling as a part of Canada, no ISO code provided here.
        "va": { pos: { x: "-55", y: "-199" } }, // Vatican City
        "fi": { pos: { x: "-73", y: "-199" } }, // Finland
        "bw": { pos: { x: "-91", y: "-199" } }, // Botswana
        "lk": { pos: { x: "-109", y: "-199" } }, // Sri Lanka
        // "Ossetia" - Special region, handling separately, no ISO code provided here.
        "it-82": { pos: { x: "-145", y: "-199" } }, // Sicily, Italy
        "cl": { pos: { x: "-163", y: "-199" } }, // Chile
        "ba": { pos: { x: "-181", y: "-199" } }, // Bosnia and Herzegovina
        "li": { pos: { x: "-199", y: "-199" } }, // Liechtenstein
        "by": { pos: { x: "-217", y: "-1" } }, // Belarus
        "ir": { pos: { x: "-217", y: "-19" } }, // Iran
        "fj": { pos: { x: "-217", y: "-37" } }, // Fiji
        "bh": { pos: { x: "-217", y: "-55" } }, // Bahrain
        "ve": { pos: { x: "-217", y: "-73" } }, // Venezuela
        "tl": { pos: { x: "-217", y: "-91" } }, // East Timor
        "az": { pos: { x: "-217", y: "-109" } }, // Azerbaijan
        // "Tibet" - Special consideration, no ISO code
        "no": { pos: { x: "-217", y: "-145" } }, // Norway
        "dz": { pos: { x: "-217", y: "-163" } }, // Algeria
        "ua": { pos: { x: "-217", y: "-181" } }, // Ukraine
        "gm": { pos: { x: "-217", y: "-199" } }, // Gambia
        "bd": { pos: { x: "-1", y: "-217" } }, // Bangladesh
        // "Madeira" - Part of Portugal, special handling
        "cs": { pos: { x: "-37", y: "-217" } }, // Czech Republic
        "bo": { pos: { x: "-55", y: "-217" } },  // Bolivia
        "ae": { pos: { x: "-73", y: "-217" } }, // United Arab Emirates
        "kg": { pos: { x: "-91", y: "-217" } }, // Kyrgyzstan
        "cu": { pos: { x: "-109", y: "-217" } }, // Cuba
        "sz": { pos: { x: "-127", y: "-217" } }, // Swaziland (now Eswatini)
        "he": { pos: { x: "-145", y: "-217" } }, // Israel
        "cr": { pos: { x: "-163", y: "-217" } }, // Costa Rica
        "cg": { pos: { x: "-181", y: "-217" } }, // Republic of the Congo
        "eg": { pos: { x: "-199", y: "-217" } }, // Egypt
        "kh": { pos: { x: "-217", y: "-217" } }, // Cambodia
        "mp": { pos: { x: "-235", y: "-1" } }, // Northern Mariana Islands
        "ci": { pos: { x: "-235", y: "-19" } }, // Ivory Coast (Côte d'Ivoire)
        "pg": { pos: { x: "-235", y: "-37" } }, // Papua New Guinea
        "hr": { pos: { x: "-235", y: "-55" } }, // Croatia
        "ma": { pos: { x: "-235", y: "-73" } }, // Morocco
        "mo": { pos: { x: "-235", y: "-91" } }, // Macao
        "bg": { pos: { x: "-235", y: "-109" } }, // Bulgaria
        "lr": { pos: { x: "-235", y: "-127" } }, // Liberia
        "el": { pos: { x: "-235", y: "-145" } }, // Greece
        // "Galapagos Islands" - Part of Ecuador, special handling
        "lc": { pos: { x: "-235", y: "-181" } },  // St. Lucia
        "ml": { pos: { x: "-235", y: "-199" } }, // Mali
        "da": { pos: { x: "-235", y: "-217" } }, // Denmark
        // "Rapa Nui" - Easter Island, part of Chile, special handling
        "ls": { pos: { x: "-19", y: "-235" } }, // Lesotho
        "co": { pos: { x: "-37", y: "-235" } }, // Colombia
        "pw": { pos: { x: "-55", y: "-235" } }, // Palau
        "ga": { pos: { x: "-73", y: "-235" } }, // Ireland
        "pf": { pos: { x: "-91", y: "-235" } }, // French Polynesia
        "tt": { pos: { x: "-109", y: "-235" } }, // Trinidad and Tobago
        "nu": { pos: { x: "-127", y: "-235" } }, // Niue
        "hk": { pos: { x: "-145", y: "-235" } }, // Hong Kong
        "sv": { pos: { x: "-163", y: "-235" } }, // Sweden
        "ht": { pos: { x: "-181", y: "-235" } }, // Haiti
        "dm": { pos: { x: "-199", y: "-235" } }, // Dominica
        "vu": { pos: { x: "-217", y: "-235" } }, // Vanuatu
        "pe": { pos: { x: "-235", y: "-235" } }, // Peru
        "gq": { pos: { x: "-253", y: "-1" } }, // Equatorial Guinea
        "uz": { pos: { x: "-253", y: "-19" } }, // Uzbekistan (Note: Corrected typo from "uzbekistn" to "uzbekistan")
        "to": { pos: { x: "-253", y: "-37" } }, // Tonga
        "ph": { pos: { x: "-253", y: "-55" } },  // Philippines
        "nf": { pos: { x: "-253", y: "-73" } }, // Norfolk Island
        "mt": { pos: { x: "-253", y: "-91" } }, // Malta
        "tj": { pos: { x: "-253", y: "-109" } }, // Tajikistan
        // "Melilla" - Spanish territory in North Africa, special handling
        // "ar": { pos: { x: "-253", y: "-145" } }, // Argentina
        "sd": { pos: { x: "-253", y: "-163" } }, // Sudan
        "af": { pos: { x: "-253", y: "-181" } }, // South Africa
        "mq": { pos: { x: "-253", y: "-199" } }, // Martinique
        "tw": { pos: { x: "-253", y: "-217" } }, // Taiwan
        // "Sahrawi Arab Democratic Republic" - Disputed territory, special handling
        "gg": { pos: { x: "-1", y: "-253" } }, // Guernsey
        "rw": { pos: { x: "-19", y: "-253" } }, // Rwanda
        "gu": { pos: { x: "-37", y: "-253" } }, // Guam
        "ps": { pos: { x: "-55", y: "-253" } }, // Palestine
        "gd": { pos: { x: "-73", y: "-253" } }, // Grenada
        "pl": { pos: { x: "-91", y: "-253" } }, // Poland
        "md": { pos: { x: "-109", y: "-253" } }, // Moldova
        "gi": { pos: { x: "-127", y: "-253" } }, // Gibraltar
        "mw": { pos: { x: "-145", y: "-253" } }, // Malawi
        "fk": { pos: { x: "-163", y: "-253" } },  // Falkland Islands
        "gb-eng": { pos: { x: "-181", y: "-253" } }, // England
        "vi": { pos: { x: "-199", y: "-253" } }, // U.S. Virgin Islands
        "im": { pos: { x: "-217", y: "-253" } }, // Isle of Man
        "vn": { pos: { x: "-235", y: "-253" } }, // Vietnam
        "ne": { pos: { x: "-271", y: "-1" } }, // Niger
        "tc": { pos: { x: "-271", y: "-19" } }, // Turks and Caicos Islands
        "mv": { pos: { x: "-271", y: "-37" } }, // Maldives
        "sn": { pos: { x: "-271", y: "-55" } }, // Senegal
        "us": { pos: { x: "-271", y: "-73" } }, // United States
        "nr": { pos: { x: "-271", y: "-91" } }, // Nauru
        "tm": { pos: { x: "-271", y: "-109" } }, // Turkmenistan
        "sg": { pos: { x: "-271", y: "-127" } }, // Singapore
        "ly": { pos: { x: "-271", y: "-145" } }, // Libya
        "ye": { pos: { x: "-271", y: "-163" } }, // Yemen
        // "Transnistria" - Disputed territory, special handling
        "tk": { pos: { x: "-271", y: "-199" } }, // Tokelau
        "mk": { pos: { x: "-271", y: "-217" } }, // North Macedonia (formerly known as the Republic of Macedonia)
        "nl": { pos: { x: "-271", y: "-235" } }, // Netherlands
        "th": { pos: { x: "-271", y: "-253" } },  // Thailand
        "me": { pos: { x: "-1", y: "-271" } }, // Montenegro
        "vc": { pos: { x: "-19", y: "-271" } }, // St. Vincent and the Grenadines
        // "Azores Islands" - Part of Portugal, special handling
        "mg": { pos: { x: "-55", y: "-271" } }, // Madagascar
        "ca": { pos: { x: "-289", y: "-109" } }, // Canada - catalonia
        "ss": { pos: { x: "-91", y: "-271" } }, // South Sudan
        "je": { pos: { x: "-109", y: "-271" } }, // Jersey
        // "Somaliland" - Disputed territory, special handling
        "cd": { pos: { x: "-145", y: "-271" } }, // Democratic Republic of the Congo
        // "Sint Eustatius" - Part of the Netherlands, special handling
        "ws": { pos: { x: "-181", y: "-271" } }, // Samoa
        "mx": { pos: { x: "-199", y: "-271" } }, // Mexico
        "sc": { pos: { x: "-217", y: "-271" } }, // Seychelles
        // "NATO" - Not a country, special handling
        "pt-BR": { pos: { x: "-253", y: "-271" } }, // Brazil - pt br
        "ge": { pos: { x: "-271", y: "-271" } }, // Georgia
        // "Sardinia" - Part of Italy, special handling
        "mn": { pos: { x: "-289", y: "-19" } }, // Mongolia
        // "European Union" - Not a country, special handling
        "ki": { pos: { x: "-289", y: "-73" } }, // Kiribati
        // "Hawaii" - Part of the United States, special handling
        // "Catalonia" - Part of Spain, special handling
      },
    }
  },

  created () {
    this.$emit('loading', `${this.$t('loadingMessage.fetchingLocales')}...`)
    LocaleDataService.getAll()
      .then(({ data }) => {
        this.availableLang = data
      }).catch(() => {
        this.toastDanger(this.$t('errors.localesNotFound'), this.$t('errors.serverIssue'))
      }).finally(() => {
        this.$emit('loaded')
      })
  },

  computed: {
    currentLang: {
      get () {
        return this.$i18n.locale
      },
      set (l) {
        this.$i18n.locale = l
      },
    },

    selectableLang () {
      return this.availableLang.filter(l => l.tag !== this.currentLang)
    },
  },

  methods: {
    getFlag(lang) {
      if (this.flags[lang]) {
        return {
          backgroundPositionX: this.flags[lang].pos.x + 'px',
          backgroundPositionY: this.flags[lang].pos.y + 'px',
        }
      }else {
        return false
      }
    },

    changeLang (lang) {
      this.currentLang = lang

      document.activeElement.blur()
    },
  },
}
</script>

<style lang="scss">
#language-list {
  .dropdown-menu {
    min-width: unset;
    padding: 0.15rem 0;

    .dropdown-item {
      padding: 0.5rem 0.75rem;
    }
  }
}

.flag {
  width: 16px; height: 16px;
  background: url('../themes/img/css_sprites.png');
}
</style>
