<template>
  <div id="app">
    <c-header
      v-model="isAdmin"
      @loading="incLoading"
      @loaded="decLoading()"
      @home="redirectHome()"
    />

    <b-row
      v-if="loading > 0"
      id="global-loader"
      align-v="center"
    >
      <b-col>
        <div
          id="global-loader-content"
          class="container text-center"
        >
          <b-spinner
            variant="primary"
            :label="`${$t('global.loading')}...`"
          />
          <h3 class="m-0 p-0">{{ loadingText }}</h3>
        </div>
      </b-col>
    </b-row>

    <main>
      <b-container class="py-2 px-0">
        <b-row no-gutters>
          <b-col>
            <div v-if="!allConfigValid">
              <b-alert
                class="break-spaces"
                variant="danger"
                show
              >
                {{ $t('alerts.configInvalid', { appName }) }}
              </b-alert>
            </div>

            <div
              v-if="isAdmin"
              class="mt-1 mb-3"
            >
              <b-sidebar
                id="admin-sidebar"
                bg-variant="light"
                width="40%"
                :title="adminSidebarTitle"
                backdrop
                right
                shadow
              >
                <component :is="adminSidebarComponent" />
              </b-sidebar>

              <b-card title="Administration">
                <b-button
                  v-b-toggle.admin-sidebar
                  variant="primary"
                  @click="openAdminSidebar('Configuration management', 'Configurations')"
                >
                  Configuration management
                </b-button>
              </b-card>
            </div>

            <div v-if="!userChoice">
              <b-card
                id="user-choice"
                no-body
              >
                <b-card-body>
                  <b-card-title class="h2 mt-2 text-center break-spaces">
                    {{ welcomeLabel }}
                  </b-card-title>

                  <b-card-text class="px-4 mt-3 text-justify break-spaces">{{ welcomeIntroduction }}</b-card-text>

                  <b-row class="mt-5">
                    <b-col class="text-center">
                      <b-btn
                        variant="primary"
                        class="starter-buttons mb-2 break-spaces"
                        @click="createNewCase()"
                      >
                        {{ welcomeSubmitCase }}
                      </b-btn>
                    </b-col>
                    <b-col class="text-center">
                      <b-btn
                        variant="light"
                        class="starter-buttons break-spaces"
                        @click="viewCase()"
                      >
                        {{ welcomeFollowCaseLabel }}
                      </b-btn>
                    </b-col>
                  </b-row>

                  <b-card-text class="mt-5 mb-0">
                    <b-alert
                      class="text-center break-spaces"
                      variant="dark"
                      show
                    >
                      {{ welcomeIntroductionFooter }}
                    </b-alert>
                  </b-card-text>
                </b-card-body>
              </b-card>
            </div>

            <div v-else-if="followCaseChosen">
              <b-card
                id="follow-case"
                no-body
              >
                <b-card-body>
                  <b-card-title class="mt-3 break-spaces">{{ welcomeFollowCaseLabel }}</b-card-title>

                  <b-form
                    class="mt-4"
                    @submit.stop.prevent="viewCase()"
                  >
                    <b-form-group
                      :label="welcomeFollowCaseTokenLabel"
                      label-class="break-spaces"
                    >
                      <b-form-input
                        v-model="idToken"
                        ref="id-token-input"
                        placeholder="XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX"
                        type="text"
                        required
                      />
                    </b-form-group>

                    <b-btn
                      block
                      variant="primary"
                      type="submit"
                      class="mt-5 break-spaces"
                    >
                      {{ welcomeFollowCaseLabel }}
                    </b-btn>
                  </b-form>
                </b-card-body>
              </b-card>
            </div>

            <div
              v-else
              id="case-content"
            >
              <div
                v-if="isViewCaseMode"
                class="mb-3"
              >
                <case-detail />
              </div>

              <b-tabs pills>
                <b-tab
                  :title="caseTabCaseLabel"
                  :title-item-class="{
                    'd-none': !isViewCaseMode,
                  }"
                  title-link-class="break-spaces"
                  class="p-2"
                  active
                >
                  <survey-j-s
                    :is-admin="isAdmin"
                    :user-can-answer-survey="isCreateCaseMode"
                    :is-view-case-mode="isViewCaseMode"
                    class="mb-4"
                    @loading="incLoading"
                    @loaded="decLoading()"
                  />
                </b-tab>
                <b-tab
                  v-if="isViewCaseMode"
                  :title="messageTabLabel"
                  title-link-class="break-spaces"
                  class="p-2"
                  lazy
                >
                  <message
                    :is-admin="isAdmin"
                    @loading="incLoading"
                    @loaded="decLoading()"
                  />
                </b-tab>
              </b-tabs>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </main>

    <c-footer />
  </div>
</template>

<script>
import SurveyJS from '@/components/SurveyJS'
import Message from '@/components/Message'
import Configurations from '@/components/admin/Configurations'
import CaseDetail from '@/components/case/CaseDetail'
import CFooter from '@/components/common/Footer'
import CHeader from '@/components/common/Header'
import {
  useConfigurationStore,
  useQuestionStore,
  useCaseStore,
} from '@/stores'
import { mapStores } from 'pinia'
import { requiredConfigurationList } from '@/lib/configurationList.js'
import {
  customCaseTabLabel,
  customMessageTabLabel,
  customWelcomeFollowCaseLabel,
  customWelcomeFollowCaseTokenLabel,
  customWelcomeIntroduction,
  customWelcomeIntroductionFooter,
  customWelcomeLabel,
  customWelcomeSubmitCase,
} from '@/lib/defaults'

export default {
  name: 'App',

  components: {
    CaseDetail,
    Configurations,
    CFooter,
    CHeader,
    Message,
    SurveyJS,
  },

  data () {
    return {
      userChoice: null,
      idToken: '',

      loading: 0,
      loadingText: '',
      isAdmin: false,

      adminSidebarTitle: 'Title',
      adminSidebarComponent: null,
    }
  },

  computed: {
    ...mapStores(useConfigurationStore, useQuestionStore, useCaseStore),

    caseTabCaseLabel() {
      const lng = this.$i18n.locale.toLowerCase() === 'en' ? 'default' : this.$i18n.locale.toLowerCase()
      let i18nText = ''
      try {
        i18nText = this.configurationStore.getByKeyWithLng('CUSTOM_CASE_TAB_LABEL', lng)
      } catch (e) {
        this.toastDanger(this.$t('errors.invalidJson', { key: 'CUSTOM_CASE_TAB_LABEL' }), this.$t('errors.userInput'))
      }
      return i18nText || customCaseTabLabel[lng] || customCaseTabLabel['default']
    },

    messageTabLabel() {
      const lng = this.$i18n.locale.toLowerCase() === 'en' ? 'default' : this.$i18n.locale.toLowerCase()
      let i18nText = ''
      try {
        i18nText = this.configurationStore.getByKeyWithLng('CUSTOM_MESSAGE_TAB_LABEL', lng)
      } catch (e) {
        this.toastDanger(this.$t('errors.invalidJson', { key: 'CUSTOM_MESSAGE_TAB_LABEL' }), this.$t('errors.userInput'))
      }
      return i18nText || customMessageTabLabel[lng] || customMessageTabLabel['default']
    },

    welcomeFollowCaseTokenLabel () {
      const lng = this.$i18n.locale.toLowerCase() === 'en' ? 'default' : this.$i18n.locale.toLowerCase()
      let i18nText = ''
      try {
        i18nText = this.configurationStore.getByKeyWithLng('CUSTOM_WELCOME_FOLLOW_CASE_TOKEN_LABEL', lng)
      } catch (e) {
        this.toastDanger(this.$t('errors.invalidJson', { key: 'CUSTOM_WELCOME_FOLLOW_CASE_TOKEN_LABEL' }), this.$t('errors.userInput'))
      }
      return i18nText || customWelcomeFollowCaseTokenLabel[lng] || customWelcomeFollowCaseTokenLabel['default']
    },

    welcomeFollowCaseLabel () {
      const lng = this.$i18n.locale.toLowerCase() === 'en' ? 'default' : this.$i18n.locale.toLowerCase()
      let i18nText = ''
      try {
        i18nText = this.configurationStore.getByKeyWithLng('CUSTOM_WELCOME_FOLLOW_CASE_LABEL', lng)
      } catch (e) {
        this.toastDanger(this.$t('errors.invalidJson', { key: 'CUSTOM_WELCOME_FOLLOW_CASE_LABEL' }), this.$t('errors.userInput'))
      }
      return i18nText || customWelcomeFollowCaseLabel[lng] || customWelcomeFollowCaseLabel['default']
    },

     welcomeSubmitCase () {
      const lng = this.$i18n.locale.toLowerCase() === 'en' ? 'default' : this.$i18n.locale.toLowerCase()
      let i18nText = ''
      try {
        i18nText = this.configurationStore.getByKeyWithLng('CUSTOM_WELCOME_SUBMIT_CASE', lng)
      } catch (e) {
        this.toastDanger(this.$t('errors.invalidJson', { key: 'CUSTOM_WELCOME_SUBMIT_CASE' }), this.$t('errors.userInput'))
      }
      return i18nText || customWelcomeSubmitCase[lng] || customWelcomeSubmitCase['default']
    },

    welcomeLabel () {
      const lng = this.$i18n.locale.toLowerCase() === 'en' ? 'default' : this.$i18n.locale.toLowerCase()
      let i18nText = ''
      try {
        i18nText = this.configurationStore.getByKeyWithLng('CUSTOM_WELCOME_LABEL', lng)
      } catch (e) {
        this.toastDanger(this.$t('errors.invalidJson', { key: 'CUSTOM_WELCOME_LABEL' }), this.$t('errors.userInput'))
      }
      return i18nText || customWelcomeLabel[lng] || customWelcomeLabel['default']
    },

    welcomeIntroduction () {
      const lng = this.$i18n.locale.toLowerCase() === 'en' ? 'default' : this.$i18n.locale.toLowerCase()
      let i18nText = ''
      try {
        i18nText = this.configurationStore.getByKeyWithLng('CUSTOM_WELCOME_INTRODUCTION', lng)
      } catch (e) {
        this.toastDanger(this.$t('errors.invalidJson', { key: 'CUSTOM_WELCOME_INTRODUCTION' }), this.$t('errors.userInput'))
      }
      return i18nText || customWelcomeIntroduction[lng] || customWelcomeIntroduction['default']
    },

    welcomeIntroductionFooter () {
      const lng = this.$i18n.locale.toLowerCase() === 'en' ? 'default' : this.$i18n.locale.toLowerCase()
      let i18nText = ''
      try {
        i18nText = this.configurationStore.getByKeyWithLng('CUSTOM_WELCOME_INTRODUCTION_FOOTER', lng)
      } catch (e) {
        this.toastDanger(this.$t('errors.invalidJson', { key: 'CUSTOM_WELCOME_INTRODUCTION_FOOTER' }), this.$t('errors.userInput'))
      }
      return i18nText || customWelcomeIntroductionFooter[lng] || customWelcomeIntroductionFooter['default']
    },

    allConfigValid () {
      let validity = true
      for (const c of requiredConfigurationList) {
        const apiConfig = this.configurationStore.getByKey(c.key)
        if (!apiConfig || !apiConfig.value) {
          validity = false
          break
        }
      }
      return validity
    },

    isViewCaseMode () {
      return this.caseStore.current && this.caseStore.current.hash !== ''
    },

    isCreateCaseMode () {
      return !this.isViewCaseMode
    },

    followCaseChosen () {
      return this.userChoice === 'id_token'
    },

    appName () {
      const appName = this.configurationStore.getByKey('APP_NAME')
      return (appName && appName.value) || this.$t('welcome.defaultAppName')
    },

    footerConfig () {
      const config = this.configurationStore.getByKey('FOOTER_JSON_CONFIG')
      return (config && config.value) || false
    },
  },

  methods: {
    redirectHome () {
      this.userChoice = null
      this.caseStore.reset()
    },

    createNewCase () {
      if (this.allConfigValid) {
        this.userChoice = 'create'
      }
    },

    viewCase () {
      if (this.allConfigValid) {
        if (!this.followCaseChosen) {
          this.userChoice = 'id_token'

          this.$nextTick(() => {
            this.$refs['id-token-input'].focus()
          })
        } else {
          this.caseStore.findByHash({ hash: this.idToken })
            .then((current) => {
              if (current) this.userChoice = 'view'
              else this.toastDanger(this.$t('errors.invalidTokenID'), this.$t('errors.userInput'))
            }).catch(() => {
              this.toastDanger(this.$t('errors.caseNotFound'), this.$t('errors.serverIssue'))
            })
        }
      }
    },

    incLoading (text = '') {
      this.loading++
      this.loadingText = text || this.$t('loadingMessage.global', { appName: this.appName })
    },

    decLoading () {
      this.loading--
      if (this.loading < 0) this.loading = 0
    },

    openAdminSidebar (title, cTitle) {
      this.adminSidebarTitle = title
      this.adminSidebarComponent = cTitle
    },
  },
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f3f3f5;
  color: #2c3e50;
  margin-bottom: 120px;
  min-height: 100vh;
  position: relative;
}

fieldset legend {
  font-size: 1.75rem;
  margin-bottom: 0.25rem;
  border-bottom: none;
}
</style>

<style lang="scss" scoped>
#global-loader {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 10;

  &::before {
    content: "";
    background-color: $gray-700;
    opacity: 0.5;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  #global-loader-content {
    background-color: $gray-200;
    height: 200px;
    margin: 0 auto;
    padding: 3.5rem 2.5rem;
  }
}

#user-choice,
#follow-case {
  min-height: 35rem;
}

.starter-buttons {
  font-size: 1.25rem;
}
@media only screen and (max-width: 600px) {
  .starter-buttons {
    width: 18rem;
  }
}
@media only screen and (min-width: 600px) {
  .starter-buttons {
    width: 25rem;
  }
}
</style>
